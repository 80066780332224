import Vue from "vue";
import Router from "vue-router";
import Coaches from "@/components/pages/Coaches/Index";
// Components
import Frame from "@/components/pages/Frame";
import GroepslesDetail from "@/components/pages/GroepslesDetail";
import GroepslesPlanning from "@/components/pages/GroepslesPlanning/Index";
import Groepslessen from "@/components/pages/Groepslessen/Index";
import Home from "@/components/pages/Home/Index";
import KpiDashboard from "@/components/pages/KpiDashboard/Index";
import Leden from "@/components/pages/Leden/Index";
import Lidmaatschappen from "@/components/pages/Lidmaatschappen/Index";
import Login from "@/components/pages/Login";
import Settings from "@/components/pages/Settings/Index";
import SettingCoaches from "@/components/pages/Settings/partials/SettingCoaches";
import SettingMembershipTargets from "@/components/pages/Settings/partials/SettingMembershipTargets";
// import axios from 'axios';
import clubAuth from "@/lib/club-auth";
import store from "@/store/store";

Vue.use(Router);

const router = new Router({
	linkActiveClass: "s_active",
	routes: [
		// routes
		{ name: "login", path: "/login", component: Login },
		{
			path: "/",
			component: Frame,
			children: [
				// routes
				{ name: "coaches", path: "/coaches", component: Coaches },
				{
					name: "groepslessen",
					path: "groepslessen",
					component: Groepslessen,
					// children: [
					//     // this is also a route record
					//     { path: 'detail', component: GroepslesDetail },
					// ],
				},
				{ name: "home", path: "", component: Home },
				{
					name: "settings",
					path: "/settings",
					component: Settings,
					redirect: {
						name: "settings/coaches",
					},
					children: [
						{
							name: "settings/targets",
							path: "targets",
							component: SettingMembershipTargets,
							beforeEnter(to, from, next) {
								if (!store.state.auth.userInfo.admin) {
									if (from.name == null) {
										return next("/");
									}
									return next(false);
								}
								next();
							},
						},
						{
							name: "settings/coaches",
							path: "coaches",
							component: SettingCoaches,
						},
					],
				},
				{
					name: "kpi-dashboard",
					path: "kpi-dashboard",
					component: KpiDashboard,
				},
				{ name: "leden", path: "leden", component: Leden },
				{
					name: "lidmaatschappen",
					path: "lidmaatschappen",
					component: Lidmaatschappen,
				},
				{
					name: "groepsles-planning",
					path: "groepsles-planning",
					component: GroepslesPlanning,
				},
				{
					name: "groepsles-detail",
					path: "groepsles-planning/detail/:id",
					props: true,
					component: GroepslesDetail,
				},
				{
					name: "Coach momenten",
					path: "coach-momenten",
					component: async () =>
						await import(
							/* webpackChunkName: "pages/coach-momenten" */ "@/components/pages/CoachMomenten/Index.vue"
						),
				},
			],
		},
	],
});

router.beforeEach((to, from, next) => {
	if (!clubAuth.idAuthorized() && to.name !== "login") {
		return next("/login");
	} else if (clubAuth.idAuthorized() && to.name === "login") {
		return next(from.path);
	}

	return next();
});

router.beforeEach((to, from, next) => {
	setTimeout(() => {
		window.scrollTo(0, 0);
	}, 100);
	return next();
});

// axios.interceptors.response.use(response => response, (error) => {
//     debugger;
//     if (error.status === 401) {
//         localStorage.removeItem('club_access_token');
//         localStorage.removeItem('club_refresh_token');
//         localStorage.removeItem('loglevel:webpack-dev-server');
//         localStorage.removeItem('vestigingId');
//         router.go('/login');
//     }
//     return Promise.reject(error);
// });

export default router;
