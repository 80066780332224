<template>
	<div class="pg_setting_container">
		<header class="pg_header">
			<div class="pg_header_heading">
				<h2>Beheer</h2>
			</div>
			<div class="pg_header_description">
				Hier kun je per vestiging de inloggegevens van bestaande coaches wijzigen of verwijderen.
			</div>
		</header>
		<div class="pg_setting_controls_container a_padding-top-20">
			<div class="pg_branches">
				<select :value="selectedVestigingId" class="v_transparent" @change="setSelectedVestigingId">
					<option
						v-for="(item, i) in sortedVestigingenByName"
						:key="`branch-option-${i}`"
						:value="item.id"
					>
						{{ item.naam }}
					</option>
				</select>
			</div>
		</div>

		<VueScrollTable
			:data="tableData"
			:headers="tableHeaders"
			:class="{ 's_no-data': !coachesByLocation.length }"
			:classes="tableStylingClasses"
			:limits="[10, 20, 30]"
			:translations="{ limit: 'per pagina' }"
			class="pg_coach-logins_table"
		>
			<template slot="heeft login?" slot-scope="{ data }">
				<div class="pg_table-slot">
					<span class="pg_table-slot_value">
						{{ data.value }}
					</span>
				</div>
			</template>

			<template #acties="{ data }">
				<div class="pg_table-slot">
					<button
						class="button v_transparent v_is-icon v_icon-size-16 v_smallest a_fill_brand_secondary pg_button"
						@click="onUpdateCoachLogin(data.attributes.coach)"
					>
						<icon icon-id="icon_edit2"></icon>
					</button>
					<button
						:disabled="!data.attributes.coach.heeftLogin"
						class="button v_transparent v_is-icon v_icon-size-16 v_smallest a_fill_brand_secondary pg_button"
						@click="onDeleteCoachLogin(data.attributes.coach)"
					>
						<icon icon-id="icon_cross"></icon>
					</button>
				</div>
			</template>
		</VueScrollTable>
	</div>
</template>
<script>
import VueScrollTable from "vue-scroll-table";
import { mapActions, mapGetters } from "vuex";
import { stylingClasses } from "@/lib/vue-scroll-table-settings.js";
import { logger } from "@/logger";

export default {
	name: "SettingsCoaches",
	components: {
		VueScrollTable,
	},
	data() {
		return {
			selectedVestigingId: -1,
			tableStylingClasses: stylingClasses,
			tableHeaders: [
				{
					text: "Naam",
				},
				/** { text: 'Gebruikersnaam', } */
				{
					text: "Heeft login?",
					width: 100,
				},
				{
					text: "Acties",
					width: 110,
				},
			],
		};
	},
	computed: {
		...mapGetters("vestiging", ["sortedVestigingenByName"]),
		...mapGetters("coaches", ["getCoachesByLocation"]),
		coachesByLocation() {
			return this.getCoachesByLocation(Number(this.selectedVestigingId));
		},
		tableData() {
			if (!this.coachesByLocation) return [];
			return this.coachesByLocation.reduce((acc, coach) => {
				acc.push([
					coach.naam,
					{
						value: coach.heeftLogin ? "Ja" : "Nee",
					},
					{
						attributes: {
							coach,
						},
					},
				]);
				return acc;
			}, []);
		},
	},
	async mounted() {
		if (!this.sortedVestigingenByName.length) {
			try {
				await this.getVestigingen();
			} catch (error) {
				logger.error("Error while getting branches", error);
			}
		}
		// If locations have been loaded, select the first location by default in the dropdown.
		const idFromLocalStorage = localStorage.getItem("selectedVestigingId");
		if (idFromLocalStorage) {
			this.selectedVestigingId = Number(idFromLocalStorage);
		} else {
			this.selectedVestigingId = this.sortedVestigingenByName[0]
				? this.sortedVestigingenByName[0].id
				: -1;
		}
		await this.getCoaches();
	},

	methods: {
		...mapActions("coaches", ["getCoaches", "updateCoachLogin", "deleteCoachLogin"]),
		...mapActions("modal", ["openModal", "closeModal"]),
		setSelectedVestigingId(event) {
			const id = event.target.value;
			this.selectedVestigingId = id;
			logger.log("id", id);
			localStorage.setItem("selectedVestigingId", id);
		},
		onUpdateCoachLogin(coach) {
			this.openModal({
				name: "coach-login",
				data: { coach },
			});
		},
		onDeleteCoachLogin(coach) {
			const id = this.selectedVestigingId;

			this.openModal({
				name: "assign-members",
				data: {
					isOpen: true,
					coach,
					selectedVestigingId: id,
					callback: () => {
						this.getCoaches();
					},
				},
			});
		},
	},
};
</script>
<style lang="scss" scoped>
.pg_coach-logins_table {
	.pg_table-slot {
		display: flex;
		align-items: center;
		// justify-content: space-between;

		.pg_table-slot_value {
			flex: 1 auto;
			text-align: left;
		}

		.pg_button {
			margin: 0;
		}
	}
}
</style>
