import config from "@/config";
import clubAuth from "@/lib/club-auth";
import { logger } from "@/logger";

const module = {
	namespaced: true,
	state: {
		coaches: [],
	},
	getters: {
		getCoachesByLocation(state) {
			return (locationId) => {
				// Make sure that locationId is a number.
				locationId = Number(locationId);

				return state.coaches.filter((coach) => coach.vestigingenIds.includes(locationId));
			};
		},
	},
	mutations: {
		SET_COACHES(state, coaches) {
			state.coaches = coaches;
		},
		UPDATE_COACH(state, updatedCoach) {
			const index = state.coaches.find((coach) => coach.id === updatedCoach.id);

			if (index !== -1) {
				state.coaches[index] = updatedCoach;
				state.coaches = [...state.coaches];
			} else {
				logger.log("Unable to update coach login in state");
			}
		},
		DELETE_COACH(state, deletedCoachId) {
			const index = state.coaches.find((coach) => coach.id === deletedCoachId);
			if (index !== -1) {
				state.coaches = [...state.coaches.slice(0, index), ...state.coaches.slice(index + 1)];
			} else {
				logger.log("Unable to delete coach login from state");
			}
		},
	},
	actions: {
		async getTable(store, { opening, periode, index, days, periodType }) {
			const diffType = {
				dag: "days",
				week: "weeks",
				maand: "months",
				jaar: "years",
			}[periode];
			const date = opening.clone().add(index, diffType).startOf(diffType.slice(0, -1));
			const res = await clubAuth.http.get(
				`/coaches/tabel/${
					periodType === "variabel" ? periodType : periode
				}?datum=${date.format("DD-MM-YYYY")}&dagen=${days}`,
			);
			return res.data;
		},
		async getCoaches({ commit }) {
			const result = await clubAuth.http.get(`${config.club.server}/coaches`);
			commit("SET_COACHES", result.data);
		},
		async updateCoachLogin({ commit, state }, data) {
			if (!data.coachId) {
				throw new Error("Missing coach ID when updating coach login");
			}
			const result = await clubAuth.http.post(`${config.club.server}/coachlogin`, data);

			if (result.status === 200) {
				commit("UPDATE_COACH", {
					...state.coaches.find((coach) => coach.id === data.coachId),
					heeftLogin: true,
				});
			}

			return result;
		},
		async deleteCoachLogin({ commit, state }, { id, replacementId }) {
			if (!id) {
				throw new Error("Missing coach ID when deleting coach login");
			}

			let result;
			if (!replacementId) {
				result = await clubAuth.http.delete(`${config.club.server}/coachlogin/${id}`);
			} else {
				result = await clubAuth.http.post(`${config.club.server}/coach/${id}/verwijderen`, {
					coachId: replacementId,
				});
			}

			if (result.status === 200) {
				commit("UPDATE_COACH", {
					...state.coaches.find((coach) => coach.id === id),
					heeftLogin: false,
				});
			}

			return result;
		},
	},
};

export default module;
