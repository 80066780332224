












































import { type PropType, defineComponent, toRefs } from "@vue/composition-api";
import { useMutation, useQueryClient } from "@tanstack/vue-query";
import Icon from "@/components/elements/Icon.vue";
import { coreApi } from "@/lib/backend";
import { DAYS_LABEL } from "@/lib/backend/helpers";
import { buttonVariants } from "@/lib/pfg/components/button";
import { logger } from "@/logger";
import { useRoosterStore } from "@/pinia/rooster";

export default defineComponent({
	name: "ModalCoachMomentDelete",
	components: {
		Icon,
	},
	props: {
		data: {
			type: Object as PropType<{
				id: number;
				day: string;
				time: string;
				gymId: string;
				gymName: string;
			}>,
			default: () => ({}),
		},
	},
	emits: ["close"],
	setup($props, { emit }) {
		const roosterStore = useRoosterStore();

		const { data: props } = toRefs($props);
		const queryClient = useQueryClient();

		const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation({
			mutationKey: ["roosters", "delete"] as const,
			mutationFn: async (
				variables: Parameters<typeof coreApi.appointmentSlots.appointmentSlotsControllerDelete>[0],
			) =>
				await coreApi.appointmentSlots
					.appointmentSlotsControllerDelete(variables)
					.then((response) => response.data),
		});

		async function submit() {
			try {
				await mutateAsync([String(props.value.id)]);

				queryClient.refetchQueries({ queryKey: ["roosters", "all"] });
				queryClient.refetchQueries({ queryKey: ["roosters", roosterStore.id, "all"] });
				queryClient.refetchQueries({ queryKey: ["roosters", roosterStore.id, props.value.gymId] });

				emit("close");
			} catch (error) {
				logger.error("Failed to delete coach moment", error);
			}
		}

		return { buttonVariants, DAYS_LABEL, props, submit, isLoading, isSuccess, isError, error };
	},
});
