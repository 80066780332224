import axios from "axios";
import { performForAllApis } from "@/lib/backend";
import { logger } from "@/logger";

const servers = {};

function create({
	server = "",
	headers,
	prefix,
	authData = {},
	authUrl,
	refreshUrl,
	checkUrl,
	needsAuthorization,
}) {
	if (!prefix) {
		logger.error("please specify a prefix");
	}
	if (servers[prefix]) {
		logger.log("PREFIX ALREADY EXIST, RETURNING EXISTING");
		return servers[prefix];
	}

	function generate() {
		const http = axios.create({
			baseURL: server,
			headers,
		});

		const requestsToRetry = [];

		let _accessToken = "";
		let _refreshToken = "";
		let _loggingIn = false;
		let _refreshing = false;
		let _authorized = false;

		// check if local storage is available
		function hasLocalStorageSurpport() {
			const test = String(Math.random()).replace(".", "");
			try {
				localStorage.setItem(test, test);
				localStorage.removeItem(test);
				return true;
			} catch (e) {
				return false;
			}
		}

		function clearTokens() {
			_accessToken = null;
			_refreshToken = null;
			if (hasLocalStorageSurpport()) {
				localStorage.removeItem(`${prefix}_access_token`);
				localStorage.removeItem(`${prefix}_refresh_token`);
			}
		}

		// write tokens to local storage
		function setTokens({ accessToken, refreshToken }) {
			logger.log("set");
			if (hasLocalStorageSurpport) {
				if (accessToken) {
					localStorage.setItem(`${prefix}_access_token`, accessToken);
				}
				if (refreshToken) {
					localStorage.setItem(`${prefix}_refresh_token`, refreshToken);
				}
			}
			_refreshToken = refreshToken;
			_accessToken = accessToken;
			http.defaults.headers.common.Authorization = `Bearer ${_accessToken}`;
			performForAllApis(
				(api) => (api.instance.defaults.headers.common.Authorization = `Bearer ${_accessToken}`),
			);
		}

		function authorize(payload) {
			if (!authUrl) {
				return logger.error("no auth url available");
			}
			logger.log("clear");
			clearTokens();
			_authorized = false;
			_loggingIn = true;
			return http
				.post(authUrl, Object.assign(authData, payload))
				.then((response) => {
					logger.log("auth res", response);
					if (response && response.status === 200) {
						setTokens({
							accessToken: response.data.access_token,
							refreshToken: response.data.refresh_token,
						});
						_authorized = true;
					} else {
						_authorized = false;
					}
					_loggingIn = false;
					return _authorized;
				})
				.catch((err) => {
					_loggingIn = false;
					logger.error("auth err", err);
				});
			// .then(() => {
			//     _loggingIn = false;
			// });
		}

		function deauthorize() {
			logger.log("deauth");
			clearTokens();
			needsAuthorization();
		}

		function refresh() {
			logger.log("trying refresh");
			return new Promise((resolve, reject) => {
				if (!refreshUrl) {
					return reject("no refresh url");
				}
				if (!_refreshToken) {
					return reject("no refresh token");
				}
				_refreshing = true;
				return http
					.post(refreshUrl, Object.assign(authData, { token: _refreshToken }))
					.then((response) => {
						logger.log(response);
						if (response.status === 200) {
							logger.log("sucessful refresh", response);
							setTokens({
								accessToken: response.data.access_token,
								refreshToken: response.data.refresh_token,
							});
							resolve(response);
						} else {
							reject("didn't receive valid tokens after refresh");
						}
					})
					.catch((err) => {
						reject(err);
						logger.error(err);
					})
					.then(() => {
						_refreshing = false;
					});
			});
		}

		// this should run on startup to see if there are local tokens
		function getLocalTokens() {
			if (hasLocalStorageSurpport()) {
				_accessToken = localStorage.getItem(`${prefix}_access_token`) || false;
				_refreshToken = localStorage.getItem(`${prefix}_refresh_token`) || false;
				if (_accessToken) {
					http.defaults.headers.common.Authorization = `Bearer ${_accessToken}`;
					performForAllApis(
						(api) =>
							(api.instance.defaults.headers.common.Authorization = `Bearer ${_accessToken}`),
					);
				}
			}
			return false;
		}

		function validateTokens() {
			if (!_accessToken && !_refreshToken) {
				return needsAuthorization("no tokens");
			}
			return http
				.get(checkUrl)
				.then(() => {
					_authorized = true;
				})
				.catch((err) => {
					logger.error("validate tokens err", err);
					// needsAuthorization();
					// clearTokens();
				});
		}

		// function retryRequests() {
		//     requestsToRetry.forEach((request) => {
		//         http.request(request);
		//     });
		// }

		http.interceptors.request.use(
			(req) => {
				if (req.headers && _accessToken) {
					req.headers.Authorization = `Bearer ${_accessToken}`;
				} else {
					logger.log("no auth headers", req.headers, " or _accesstoken", _accessToken);
				}
				return Promise.resolve(req);
			},
			(err) => {
				return Promise.reject(err);
			},
		);

		http.interceptors.response.use(
			(response) => response,
			(err) => {
				const originalRequest = err.config;
				if (
					!err.response ||
					(err.response.status === 401 && err.response.statusText === "Unauthorized" && !_loggingIn)
				) {
					logger.log("401");
					// needsAuthorization(err);
					if (!_refreshing) {
						refresh()
							.then((data) => {
								logger.log("refreshed token", data);
								logger.log("request to repeat", requestsToRetry);
								requestsToRetry.forEach((resolve) => resolve());
							})
							.catch((refreshErr) => {
								clearTokens();
								needsAuthorization(refreshErr);

								logger.error("refresh err", refreshErr);

								// return Promise.reject(err);
							});
					}
					originalRequest._retry = true;
					return new Promise((resolve) => {
						requestsToRetry.push(resolve);
					}).then(() => {
						return axios.request(originalRequest);
					});
				}
				return Promise.reject(err);
			},
		);

		function idAuthorized() {
			return localStorage.getItem(`${prefix}_access_token`);
		}

		getLocalTokens();

		return {
			http,
			authorize,
			deauthorize,
			validateTokens,
			idAuthorized,
		};
	}
	servers[prefix] = generate();
	return servers[prefix];
}

function get(prefix) {
	if (!servers[prefix]) {
		logger.trace("auther:", prefix, "does not exist");
		return false;
	}
	return servers[prefix];
}

export default {
	create,
	get,
};
