<template>
	<div class="pg_content_1-1">
		<header class="pg_header">
			<div class="pg_header_heading">
				<h1 class="s_elem-has-icon-left a_fill_default">Coach login</h1>
			</div>
		</header>

		<div class="pg_coach-login_form">
			<label>
				Gebruikersnaam
				<input v-model="username" v-validate="" name="username" type="text" placeholder="" />
			</label>
			<label>
				Wachtwoord
				<input
					ref="password"
					v-model="password"
					v-validate=""
					name="password"
					type="password"
					placeholder=""
				/>
			</label>
			<label>
				Wachtwoord (bevestiging)
				<input
					v-model="passwordHerhaling"
					v-validate="'confirmed:password'"
					name="wachtwoord (bevestiging)"
					type="password"
					placeholder=""
				/>
				<span v-show="errors.has('wachtwoord (bevestiging)')" class="v_has-error"
					>wachtwoorden komen niet overeen</span
				>
			</label>
			<span v-show="isInvalidAndDirty" class="v_has-error"
				>Gebruikersnaam of wachtwoord moet een waarde hebben</span
			>
			<p v-if="closeMessage">{{ closeMessage }}</p>
		</div>

		<hr />

		<div class="a_flex a_justify_flex-end">
			<button
				class="button v_ghost_brand_secondary v_smaller a_margin-left-10"
				@click="$emit('close')"
			>
				Sluiten
			</button>
			<button
				v-if="!closeMessage"
				:disabled="!isValid"
				class="button v_brand_secondary v_smaller a_margin-left-10"
				@click="onSaveCoachLogin"
			>
				{{ coach.heeftLogin ? "Updaten" : "Aanmaken" }}
			</button>
		</div>
	</div>
</template>

<script>
import { mapActions } from "vuex";
import { logger } from "@/logger";

export default {
	name: "ModalCoachLogin",
	props: {
		data: {
			default: Object,
			validator(value) {
				const isDefined = Boolean(value.coach);
				if (!isDefined) {
					logger.warn("`data.coach` prop should have a value");
				}
				return isDefined;
			},
		},
	},
	data() {
		return {
			...this.data,
			closeMessage: undefined,
			username: "",
			password: "",
			passwordHerhaling: "",
		};
	},
	computed: {
		isValid() {
			return Boolean(this.username || this.password) && this.errors.items.length === 0;
		},
		isInvalidAndDirty() {
			const isFormDirty = Object.keys(this.fields).some((key) => this.fields[key].dirty);
			return isFormDirty & (!this.username && !this.password);
		},
	},
	methods: {
		...mapActions("coaches", ["getCoaches", "updateCoachLogin"]),
		onSaveCoachLogin() {
			this.updateCoachLogin({
				coachId: this.coach.id,
				username: this.username,
				password: this.password,
				passwordHerhaling: this.passwordHerhaling,
			})
				.then(() => {
					if (this.coach.heeftLogin) {
						this.closeMessage = "De login is succesvol aangepast!";
					} else {
						this.closeMessage = "De login is succesvol aangemaakt!";
					}

					this.getCoaches();
				})
				.catch((err) => {
					if (!err.message.contains("406")) {
						this.closeMessage = `Er is iets fout gegaan probeer het later nog eens of neem contact op met de beheerder: ${err.message}`;
					}
				});
		},
	},
};
</script>

<style scoped lang="scss">
.pg_coach-login_form {
	input {
		max-width: 200px;
	}
}

.v_has-error {
	color: $input-error-color;
}
</style>
