<template>
	<div>
		<header class="pfg-header">
			<div class="pfg-header_heading">
				<h1 class="s_elem-has-icon-left">Coach verwijderen</h1>
			</div>
		</header>

		<p v-if="canTransferToOtherCoach">
			Wil je de sporters van {{ data.coach.naam }} overdragen aan een andere coach?
		</p>
		<p v-else>
			Er zijn geen andere coaches beschikbaar om de sporters van
			{{ data.coach.naam }} aan over te dragen. De leden van deze coach zullen zonder coach komen te
			staan. location: {{ data.selectedVestigingId }}
		</p>

		<div v-if="canTransferToOtherCoach" class="select">
			<div
				class="select_option_selected"
				focusable="true"
				tabindex="0"
				@keydown.escape="isOpen = false"
				@click="isOpen = !isOpen"
			>
				{{ selected ? selected.naam : "Geen coach" }}
				<svg
					:class="{ reverse: isOpen }"
					width="10"
					height="8"
					viewBox="0 0 10 8"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M5.848 6.6432L9.04375 1.53C9.46003 0.86395 8.98119 -3.9633e-07 8.19575 -3.61669e-07L1.80425 -7.96194e-08C1.01881 -4.4959e-08 0.539969 0.86395 0.956249 1.53L4.152 6.6432C4.54367 7.26987 5.45633 7.26987 5.848 6.6432Z"
						fill="black"
					/>
				</svg>
			</div>
			<div v-if="isOpen" class="select_option-list">
				<div
					v-for="coach in filteredCoaches"
					:key="coach.id"
					class="select_option-list_option"
					@click="selectItem(coach)"
				>
					<div class="grid">
						<div class="user">
							<img
								class="pf"
								:src="coach.foto ? coach.foto : `https://ui-avatars.com/api/?name=${coach.naam}`"
								width="35"
								height="35"
							/>
							<div class="name">{{ coach.naam }}</div>
						</div>
						<!-- <div class="info">
              <span class="info_30"> 30% sporters:</span>
              <span class="info_value">
                30
                {{ getCoachThirtyPercentSporters(coach.id) }}
              </span>
            </div> -->
					</div>
				</div>
			</div>
		</div>

		<div
			:class="{
				invisible: isOpen,
				actions: true,
			}"
		>
			<button
				class="button v_ghost_brand_primary v_has-icon-right v_smaller a_margin-bottom-0 a_margin-top-10"
				@click="$emit('close')"
			>
				<span class="button-txt">Sluiten</span>
			</button>
			<!-- <button
        class="button v_ghost_brand_secondary v_has-icon-right v_smaller a_margin-bottom-0 a_margin-top-10"
        @click="onSubmit">
        <span class="button-txt">{{
          canTransferToOtherCoach ? 'Overslaan' : 'Verwijderen'
        }}</span>
      </button> -->
			<button
				v-if="canTransferToOtherCoach"
				:disabled="selected === null"
				class="button v_brand_secondary v_has-icon-right v_smaller a_margin-bottom-0 a_margin-top-10"
				@click="onSubmit"
			>
				<span class="button-txt">Toewijzen</span>
			</button>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { logger } from "@/logger";

export default {
	props: {
		data: {
			type: Object,
			required: false,
			default: () => ({
				coach: null,
				selectedVestigingId: -1,
				callback: undefined,
			}),
		},
	},
	data() {
		return {
			isOpen: false,
			selected: null,
			myCoaches: [],
		};
	},
	computed: {
		...mapGetters("coaches", ["getCoachesByLocation"]),

		canTransferToOtherCoach() {
			return this.filteredCoaches.length > 0;
		},
		coachesByLocation() {
			return this.getCoachesByLocation(this.data.selectedVestigingId);
		},

		filteredCoaches() {
			return this.coachesByLocation.filter((coach) => coach.id !== this.data.coach.id);

			// Coaches zonder login kunnen ook sporters hebben voor nu even niet filteren
			// .filter((coach) => coach.heeftLogin);
		},
	},
	methods: {
		...mapActions("coaches", ["deleteCoachLogin"]),
		...mapActions("modal", {
			openModal: "openModal",
		}),

		selectItem(item) {
			this.selected = item;
			this.isOpen = false;
		},

		close() {
			this.$emit("close");
			this._notify({
				type: "info",
				title: "Bezig",
				text: "De sporters worden overgedragen",
			});
		},

		async onSubmit() {
			const toDeleteCoachId = this.data.coach.id;
			const replacementCoachId = this.selected?.id;
			if (!toDeleteCoachId || !replacementCoachId) return;

			const _notify = this.$notify;

			try {
				await this.deleteCoachLogin({
					id: toDeleteCoachId,
					replacementId: replacementCoachId,
				});

				_notify({
					type: "success",
					title: "Succesvol",
					text: "De sporters zijn overgedragen",
				});

				this.data?.callback?.();
				logger.log("callback", this.data?.callback);
			} catch (error) {
				_notify({
					type: "error",
					title: "Er is iets misgegaan",
					text: error.message,
				});
			}

			this.$emit("close");
		},
	},
};
</script>

<style lang="scss" scoped>
.modal-view-note_display {
	width: 100%;
	height: 100px;
	border: none;
	border-radius: 10px;
	padding: 15px;
	font-size: 16px;
	resize: none;
	background-color: $brand-primary-lightest;
	color: $brand-primary-lighter;
	cursor: not-allowed;
	touch-action: none;

	&:focus {
		outline: none;
		background-color: $brand-primary-lightest;
		color: $brand-primary-lighter;
		border: none;
		cursor: not-allowed;
		touch-action: none;
	}
}

.select {
	cursor: pointer;
	height: 50px;
	z-index: 10;

	&_option_selected {
		border-radius: 8px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: $brand-light-gray;
		padding: 12px;
	}

	&_option-list {
		z-index: 1;
		margin-top: 5px;
		max-height: 40vh;
		border-radius: 8px;
		overflow: scroll;
		overflow: -moz-scrollbars-none;
		background-color: white;

		&::-webkit-scrollbar {
			display: none;
		}

		&_option {
			position: relative;
			cursor: pointer;
			padding: 12px;
			width: 100%;
			display: flex;
			align-items: center;

			&:hover {
				background-color: $brand-primary-lightest;
			}

			&:after {
				$to-translate: 12px;
				width: calc(100% - calc(#{$to-translate} * 2));
				height: 1px;
				content: "";
				position: absolute;
				bottom: 0;
				border: 1px solid rgba(217, 217, 217, 0.4);
				left: 0;
				transform: translateX($to-translate);
			}
		}
	}
}

.reverse {
	transform: rotate(180deg);
}

.grid {
	display: flex;
	grid-template-columns: 1fr 1fr;
	justify-content: space-between;
	.user {
		display: flex;
		gap: 10px;
		align-items: center;
		padding: 5px 0;

		.pf {
			border-radius: 50%;
			background-size: cover;
			background-position: center;
			width: 35px;
			height: 35px;
		}
	}
}

.invisible {
	opacity: 0;
	touch-action: none;
	pointer-events: none;
	z-index: -10;
}

.actions {
	display: flex;
	gap: 10px;
}

.info {
	display: flex;
	align-content: center;
	font-size: 14px;
	gap: 10px;

	&_30 {
		color: $brand-primary-lighter;
	}

	&_value {
		margin-top: 0.8px;
		color: $brand-black;
	}
}
</style>
