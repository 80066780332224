<template>
	<div id="app">
		<notifications />

		<symbols></symbols>

		<user-menu></user-menu>
		<overlay></overlay>
		<modal></modal>

		<top-bar></top-bar>

		<div class="pg_main-container">
			<side-bar></side-bar>
			<div class="pg_main-content">
				<period v-if="showPeriodPicker"></period>
				<router-view></router-view>
			</div>
		</div>

		<div id="sub-top-bar-end"></div>
	</div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import VueSticky from "vue-sticky";
import { mapActions } from "vuex";
import { shouldShowPeriodPicker } from "@/lib/should-show-period-picker";
import Modal from "./components/elements/Modal.vue";
import Overlay from "./components/elements/Overlay.vue";
import Period from "./components/elements/Period.vue";
import UserMenu from "./components/elements/UserMenu.vue";
import SideBar from "./components/parts/SideBar.vue";
import Symbols from "./components/parts/Symbols.vue";
import TopBar from "./components/parts/TopBar.vue";

const Sticky = VueSticky.default;

export default defineComponent({
	name: "App",
	directives: {
		sticky: Sticky,
	},
	components: {
		Modal,
		Overlay,
		UserMenu,
		SideBar,
		Symbols,
		TopBar,
		Period,
	},
	data() {
		return {
			lastRoute: "",
		};
	},
	computed: {
		showPeriodPicker() {
			return shouldShowPeriodPicker(this.$route.name) && this.$route.name !== "Coach momenten";
		},
	},
	watch: {},
	created() {
		this.info();
	},
	methods: {
		...mapActions("auth", ["info"]),
	},
});
</script>

<style lang="scss">
@import "./styles/fonts";

@include foundation-global-styles;
@include foundation-typography;

@include foundation-forms;
@include foundation-button;

@import "./styles/components";
@import "./styles/structure";

@import "./styles/adjustments";
@import "./styles/brand-adjustments";
</style>
