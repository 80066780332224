import config from "@/config";
import { logger } from "@/logger";
import auther from "@/services/auther";

const clubHttp = auther.create({
	server: config.club.server,
	prefix: "club",
	authUrl: `${config.club.server}/login`,
	refreshUrl: `${config.club.server}/login/refresh`,
	checkUrl: `${config.club.server}/coaches`,
	headers: {
		"Access-Control-Allow-Method": "*",
		"Content-Type": "application/json",
	},
	needsAuthorization(reason) {
		if (reason) {
			logger.log("needs authorization:", reason);
		}

		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const router = require("@/router");
		logger.log("login");
		router.default.push("/login");
	},
});

export default clubHttp;
