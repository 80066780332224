import Vue from "vue";
import Vuex from "vuex";
import { logger } from "@/logger";
import auth from "./modules/auth";
import branches from "./modules/branches";
import coaches from "./modules/coaches";
import dashboard from "./modules/dashboard";
import fitnessBlokken from "./modules/fitnessBlokken";
import groepsles from "./modules/groepsles";
import leden from "./modules/leden";
import lessen from "./modules/lessen";
import lidmaatschappen from "./modules/lidmaatschappen";
import modal from "./modules/modal";
import overlay from "./modules/overlay";
import rooster from "./modules/rooster";
import settings from "./modules/settings";
import statistieken from "./modules/statistieken";
import trainer from "./modules/trainer";
import user from "./modules/user";
import userMenu from "./modules/userMenu";
import vestiging from "./modules/vestiging";

function createStore() {
	logger.debug("creating store");
	Vue.use(Vuex);
	return new Vuex.Store({
		state: {},
		getters: {},
		modules: {
			modal,
			overlay,
			userMenu,
			groepsles,
			rooster,
			dashboard,
			statistieken,
			trainer,
			vestiging,
			branches,
			leden,
			coaches,
			lidmaatschappen,
			fitnessBlokken,
			lessen,
			auth,
			user,
			settings,
		},
	});
}

export default createStore();
