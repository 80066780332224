


























































































































































































import {
	type PropType,
	computed,
	defineComponent,
	reactive,
	ref,
	toRefs,
	watch,
} from "@vue/composition-api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { z } from "zod";
import Icon from "@/components/elements/Icon.vue";
import { coreApi, reserveerApi } from "@/lib/backend";
import type { CreateAppointmentSlotArgs } from "@/lib/backend/core.api";
import { DAYS, DAYS_LABEL, DaySchema } from "@/lib/backend/helpers";
import Plus from "@/lib/icons/micro/plus.vue";
import Trash from "@/lib/icons/micro/trash.vue";
import { buttonVariants } from "@/lib/pfg/components/button";
import { labelVariants } from "@/lib/pfg/components/label";
import { logger } from "@/logger";
import { useRoosterStore } from "@/pinia/rooster";

type Appointment = Pick<CreateAppointmentSlotArgs, "day" | "time">;

const SlotSchema = z.object({
	day: DaySchema,
	time: z.string().regex(/^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/, "Invalide tijd"),
});

export default defineComponent({
	name: "OverlayCoachMomentCreate",
	components: {
		Icon,
		Plus,
		Trash,
	},
	props: {
		data: {
			type: Object as PropType<{ vestigingId: number | "all" | undefined }>,
			default: () => ({}),
		},
	},
	emits: ["close"],
	setup($props, { emit }) {
		const { data: props } = toRefs($props);
		const queryClient = useQueryClient();
		const roosterStore = useRoosterStore();

		const { data: vestigingen } = useQuery({
			queryKey: ["vestigingen"] as const,
			queryFn: async (context) =>
				await reserveerApi.vestigingen
					.vestigingen({ signal: context.signal })
					.then((response) => response.data),
		});

		const options = reactive<
			Pick<CreateAppointmentSlotArgs, "itemType" | "duration"> & {
				gymId: CreateAppointmentSlotArgs["gymId"] | undefined;
				roomId: CreateAppointmentSlotArgs["roomId"] | undefined;
			}
		>({
			itemType: "live",
			duration: 55,
			gymId: props.value.vestigingId === "all" ? undefined : props.value.vestigingId,
			roomId: 0,
		});

		watch(
			options,
			(value) => {
				value.roomId =
					vestigingen.value
						?.find((vestiging) => vestiging.id === value.gymId)
						?.zalen?.find((zaal) => zaal.naam === "Fitness zaal")?.id || 0;
			},
			{ immediate: true, deep: true },
		);

		const vestiging = computed(() =>
			vestigingen.value?.find((vestiging) => vestiging.id === options.gymId),
		);
		const zalen = computed(() => vestiging.value?.zalen ?? []);

		const slots = ref<Partial<Appointment>[]>([
			{
				day: undefined,
				time: undefined,
			},
		]);

		const { mutateAsync, isLoading, isSuccess, isError, error } = useMutation({
			mutationKey: ["roosters", "create"] as const,
			mutationFn: async (
				variables: Parameters<typeof coreApi.appointmentSlots.appointmentSlotsControllerAdd>[0],
			) =>
				await coreApi.appointmentSlots
					.appointmentSlotsControllerAdd(variables)
					.then((response) => response.data),
		});

		async function submit() {
			try {
				await mutateAsync({
					slots: z
						.array(SlotSchema)
						.parse(
							slots.value.map((slot) => ({
								...slot,
								time: `${slot.time}:00`,
							})),
						)
						.map((slot) => ({
							...options,
							gymId: z.number().parse(options.gymId),
							roomId: z.number().parse(options.roomId),
							...slot,
						})),
				});

				queryClient.refetchQueries({ queryKey: ["roosters", "all"] });
				queryClient.refetchQueries({ queryKey: ["roosters", roosterStore.id, "all"] });
				queryClient.refetchQueries({ queryKey: ["roosters", roosterStore.id, options.gymId] });

				emit("close");
			} catch (error) {
				logger.error("Failed to create coach moment", error);
			}
		}

		return {
			buttonVariants,
			labelVariants,
			DAYS,
			DAYS_LABEL,
			props,
			vestigingen,
			vestiging,
			zalen,
			options,
			slots,
			submit,
			isSuccess,
			isLoading,
			isError,
			error,
		};
	},
});
