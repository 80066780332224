// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// import jQuery from 'jquery';
import Vue from "vue";
// @ts-expect-error Not typed
import * as NoData from "highcharts/modules/no-data-to-display";
import { VueQueryPlugin } from "@tanstack/vue-query";
import { setDefaultOptions } from "date-fns";
import { nl as dateFnsLocale } from "date-fns/locale";
import moment from "moment";
import "script-loader!foundation-sites/dist/js/foundation.min";
import "script-loader!jquery/dist/jquery.min";
import "script-loader!what-input/dist/what-input.min";
import VeeValidate, { Validator } from "vee-validate";
// @ts-expect-error Not typed
import nl from "vee-validate/dist/locale/nl";
// @ts-expect-error Not typed
import VueHighcharts from "vue-highcharts";
// @ts-expect-error Not typed
import VueLocalStorage from "vue-localstorage";
import Notifications from "vue-notification";
import Icon from "@/components/elements/Icon.vue";
import Loader from "@/components/elements/Loader.vue";
import clubHttp from "@/lib/club-auth";
import { pinia } from "@/pinia";
import "@/styles/tailwind.css";
import App from "./App.vue";
import highchartSettings from "./lib/highchartSettings";
import router from "./router";
import store from "./store/store";

setDefaultOptions({ locale: dateFnsLocale });

clubHttp.validateTokens();

Vue.use(VueQueryPlugin, { enableDevtoolsV6Plugin: true });

moment.locale("nl");
window.moment = moment;

Vue.component("loader", Loader);
Vue.component("icon", Icon);

Vue.use(VueHighcharts);
Vue.use(Notifications);
Vue.use(VueLocalStorage);
// Vue.use(VeeValidate, {
//     locale: 'nl',
//     dictionary: {
//         nl: { messages },
//     },
// });

Validator.localize({ nl: nl });
Vue.use(VeeValidate, { locale: "nl", delay: 1000 });

Vue.filter("valueFormatter", (value: number) => `${value > 0 ? "+" : ""}${value.toFixed(1)}`);

// store.dispatch('auth/initAuth');

// window.jQuery = jQuery;
// window.$ = jQuery;

// require('motion-ui');
// require('what-input');
// require('foundation-sites');

(async () => {
	// await store.dispatch('auth/info').catch(() => ({}));

	new Vue({
		router,
		store,
		render: (h) => h(App),
		pinia,
		data: {
			colors: {
				primary: "#262261",
				primaryLighter: "#7b7aa0",
				primaryLightest: "#dadae3",
				secondary: "#d4145a",
			},
		},
		mounted() {
			// @ts-expect-error Not typed
			NoData(this.Highcharts);
			// @ts-expect-error Not typed
			this.Highcharts.setOptions(highchartSettings);
		},
	}).$mount("#app");
})();
